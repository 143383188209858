import styled from "styled-components";
import React, { lazy } from "react";

const Sads = lazy(() => import("./Viewer/ViewAds.jsx"));

const Ads = (props) => {
  return (
    <Container>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Sads />
      </React.Suspense>
    </Container>
  );
};

const Container = styled.main`
  position: relative;
  /* left: 180px;
  right:180px; */
  min-height: calc(100vh - 250px);
  overflow-x: show;
  display: block;
  margin-top: 10rem;
  /* padding: 0 calc(3.5vw + 5px); */

  &:after {
    /* background: url("/images/jade-stephens-WhVO1xKiDVw-unsplash.jpg") center center / cover
      no-repeat fixed; */
    content: "";
    position: absolute;
    inset: 0px;
    z-index: -1;
  }
`;

export default Ads;
