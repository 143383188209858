import PopHomeView from "../MainView/MainHomeView";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { client } from "../../client";
// import styled from "styled-components";
const CACHE_TIMEOUT = 60; // seconds

function AppView() {
  const [videos, setVideos] = useState([]);
  // const [loading, setLoading] = useState(true);
  const cleanUpData = useCallback((rawData) => {
    const cleanData = rawData.map((videodata) => {
      const { sys, fields } = videodata;
      const { id } = sys;
      const postName = fields.projectName;
      const secuence = fields.secuence;
      const photoGallery = fields.photoGallery;
      const coverImage = fields.coverImage.fields.file.url;
      const layoutProps = fields.layoutProps;
      const coverHoverColor = fields.coverHoverColor;
      const coverHoverDiscription = fields.coverHoverDiscription;
      const hoverTextColor = fields.hoverTextColor;
      const linkImageBool = fields.linkImage;

      const updatedData = {
        id,
        postName,
        secuence,
        photoGallery,
        coverImage,
        layoutProps,
        coverHoverColor,
        coverHoverDiscription,
        hoverTextColor,
        linkImageBool,
      };

      return updatedData;
    });
    localStorage.setItem(
      "videos",
      JSON.stringify({ data: cleanData, timestamp: Date.now() })
    );
    setVideos(cleanData);
    // setLoading(false);
  }, []);

  const memoizedData = useMemo(() => videos, [videos]);

  const getData = useCallback(async () => {
    try {
      const response = await client.getEntries({ content_type: "post" });
      const responseData = response.items;
      // console.log(responseData);

      if (responseData) {
        cleanUpData(responseData);
      } else {
        setVideos([]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [cleanUpData]);

  useEffect(() => {
    const cachedVideos = JSON.parse(localStorage.getItem("videos"));

    if (cachedVideos && cachedVideos.timestamp + CACHE_TIMEOUT > Date.now()) {
      setVideos(cachedVideos.data);
      // setLoading(false);
    } else {
      getData();
    }
  }, [getData]);



  
  // if (loading) {
  //   return <Loader>Loading...</Loader>;
  // }

  return (
    <div>
      <PopHomeView data={memoizedData} />
    </div>
  );
}

// const Loader = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
// `;
export default AppView;
