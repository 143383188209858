import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      //translation file path
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
      // fetch: async (url, options) => {
      //   const response = await fetch(url, options);

      //   if (!response.ok) {
      //     throw new Error(`Failed to fetch translation: ${response.status}`);
      //   }

      //   const translations = await response.json();

      //   // You might need to transform the response data to match i18next's expected format
      //   // For example, if the API returns translations in a different structure or format,
      //   // you can map it to match the structure expected by i18next.

      //   return translations;
      // },
    },
    fallbackLng: "en",
    debug: false,
    ns: ["info"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
