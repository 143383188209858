import { createGlobalStyle } from "styled-components";
import FuturaHeavyfont from "./assets/fonts/FuturaHeavyfont.ttf";
import SilkaRegular from "./assets/fonts/Silka-Regular.otf";

const FontStyles = createGlobalStyle`

@font-face {
    font-family: "SilkaRegular";
    src: url(${SilkaRegular}) format('opentype');
    font-display: swap;
}

@font-face {
  font-family: 'FuturaHeavyfont';
  src: url(${FuturaHeavyfont}) format('truetype');
  font-display: swap;
}
`;

export default FontStyles;
