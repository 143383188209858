import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import styled from "styled-components";

const ImageWrapper = styled.div`
  flex-grow: ${(props) => (props.fullWidth ? 1 : 1)};
  flex-shrink: ${(props) => (props.fullWidth ? 1 : 1)};
  flex-basis: ${(props) => (props.fullWidth ? "100%" : "50%")};
`;

const StyledLazyImage = styled(LazyLoadImage)`
  /* max-width: 100%; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  /* flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto; */
  margin-top: -5px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 1%;
`;

const Gallery = ({ layoutPattern = ["fullWidth"], images }) => {
  return (
    <Wrapper>
      {images.map((image, index) => {
        const fullWidth =
          layoutPattern[index % layoutPattern.length] === "fullWidth";

        return (
          <ImageWrapper key={index} fullWidth={fullWidth}>
            <StyledLazyImage
              rel="preload"
              effect="blur"
              key={image.sys.id}
              src={image.fields.file.url}
              alt={image.fields.title}
              width={fullWidth ? "100%" : "auto"}
              height="100%"
            />
          </ImageWrapper>
        );
      })}
    </Wrapper>
  );
};

export default Gallery;
