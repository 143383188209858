import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ContactLink from "./contactLink";

export default function PopHomeViewItem({ data, getHomeView }) {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  return (
    <Wrap
      onClick={data.linkImageBool ? null : () => getHomeView(data)}
      ref={ref}
    >
      {data.linkImageBool ? (
        <ContactLink />
      ) : (
        <>
          <LazyLoadImage
            fetchpriority="high"
            rel="preload"
            src={data.coverImage}
            alt="Project-cover"
            placeholderSrc="https://placeholder.pics/svg/600x300/DEDEDE/555555/loading"
            effect="blur"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <HoverOverlay
            inView={inView}
            bgColor={data.coverHoverColor}
            textColor={data.hoverTextColor}
          >
            <CustomTitle>{data.postName}</CustomTitle>
            <CustomText>{data.coverHoverDiscription}</CustomText>
          </HoverOverlay>
        </>
      )}
    </Wrap>
  );
}

const Wrap = styled.div`
  display: grid;
  justify-self: stretch;

  ${(props) => props.ratio}
  object-fit:fill;

  cursor: pointer;

  transition: all 250ms cubic-bezier(0.25, 0.45, 0.45, 0.94) 0s;
  position: relative;
`;

const HoverOverlay = styled.div`
  flex-direction: column;
  font-family: "SilkaRegular";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${(props) => props.textColor};

  background-color: ${(props) => props.bgColor};
  opacity: 0;
  transition: opacity 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Wrap}:hover & {
    opacity: 1;
  }
  @media (max-width: 758px) {
    opacity: ${(props) => (props.inView ? "1" : "0")};
    transition: opacity 1s ease-in-out;
  }
`;

const CustomTitle = styled.p`
  text-transform: uppercase;
  font-size: 1.75rem;
  margin-bottom: 0;
  @media (max-width: 758px) {
    font-size: 1.2rem;
  }
`;
const CustomText = styled.p`
  font-size: 1.5rem;
  font-style: italic;
  margin-top: 1rem;
  @media (max-width: 758px) {
    font-size: 0.9rem;
  }
`;
