import styled from "styled-components";
import { NavLink } from "react-router-dom";
import FontStyles from "../fontStyles";

const Header = (props) => {
  return (
    <>
      <FontStyles />
      <Nav>
        <Logo>
          <NavLink rel="preload" className="nav-link" to="/home" alt="Home">
            <span>Neringa Nina</span>
          </NavLink>
        </Logo>
        <NavMenu>
          <NavLinkStyled
            rel="preload"
            className={({ isActive }) => (isActive ? "active-style" : "")}
            to="/works"
            alt="works"
          >
            <span>works</span>
          </NavLinkStyled>
          <NavLinkStyled
            rel="preload"
            className={({ isActive }) => (isActive ? "active-style" : "")}
            to="/info"
            alt="info"
          >
            <span>about & contact</span>
          </NavLinkStyled>
        </NavMenu>
      </Nav>
    </>
  );
};

const NavLinkStyled = styled(NavLink)`
  color: #000;
  display: flex;
  align-items: center;
  margin-right: 10%;
  padding: 0px 1rem;
  text-decoration: none;

  /* Updated part */
  &.active-style {
    color: #122;
    text-decoration: underline;
  }
`;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 36px;
  /* letter-spacing: 16px; */
  z-index: 3;
  @media (max-width: 768px) {
    padding: 0px 16px;
  }
`;
const Logo = styled.span`
  font-family: "FuturaHeavyfont";
  font-size: 1.5rem;
  padding: 0px;
  width: 7.5rem;
  margin-top: 4px;
  max-height: 70px;
  display: inline-block;
  margin-left: 2%;
  @media (max-width: 768px) {
    margin-left: 0px;
  }
  img {
    display: block;
    width: 100%;
    margin-left: 1rem;
  }
  /* @media (max-width:900px){
        margin-left:20px;
    } */
  &[rel="preload"] {
    font-family: "FuturaHeavyfont";
  }
`;
const NavMenu = styled.div`
  display: flex;

  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: flex-end;
  margin: 0px;
  padding: 0px;
  position: relative;
  margin-left: auto;
  /* margin-right: 10%; */

  a {
    color: #000;
    display: flex;
    align-items: center;
    margin-right: 10%;
    padding: 0px 1rem;
    text-decoration: none;

    @media (max-width: 768px) {
      padding: 0px 8px;
      margin-right: 0;
    }
    @media (min-width: 1920px) {
      margin-right: 14%;
    }

    span {
      font-family: "SilkaRegular";
      /* all letters caps */
      text-transform: uppercase;
      color: black;
      font-size: 0.75rem;
      padding: 4px 0px;
      white-space: nowrap;
      position: relative;
    }
    &:hover {
      color: #122;
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    margin-left: 0px;
  }
  &[rel="preload"] {
    font-family: "SilkaRegular";
  }
`;

export default Header;
