import styled from "styled-components";
// import ViewersInfo from "./Viewer/ViewerInfo";
import React from "react";

const Sinfo = React.lazy(() => import("./Viewer/ViewerInfo"));

const Info = (props) => {
  return (
    <Container>
      <React.Suspense
        fallback={
          <div style={{ display: "flex", justifyContent: "center" }}>
            Loading...
          </div>
        }
      >
        <Sinfo />
      </React.Suspense>
    </Container>
  );
};

const Container = styled.main`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: start;
  position: relative;
  min-height: calc(100vh - 250px);
  overflow-x: hidden;
  padding: 0 calc(13.5vw);
  margin-top: 10rem;
  /* top:100px; */
  align-items: center;
  img {
    width: 100%;
    /* max-height: 10%; */
    object-fit: fill;
  }
  @media (max-width: 768px) {
    margin-top: 15vh;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
`;

export default Info;
