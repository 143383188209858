import React, { useEffect } from "react";
import { CloseBtn, Overlay, ModalContainer } from "../Styles/PopUp.Styled.js";
import { motion } from "framer-motion";
import Gallery from "../ImageGallery.jsx";

function HomeView(props) {
  useEffect(() => {
    function escFunction(event) {
      if (event.key === "Escape") {
        props.onHide();
      }
    }
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [props]);

  return (
    <React.Fragment>
      {props.show && (
        <Overlay
          as={motion.div}
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          animate={{ opacity: 1 }}
          // exit={{ opacity: 0 }}
        >
          <CloseBtn onClick={props.onHide}>&times;</CloseBtn>
          <ModalContainer>
            <Gallery
              layoutPattern={props.layoutProps}
              images={props.photoGallery}
            />
          </ModalContainer>
        </Overlay>
      )}
    </React.Fragment>
  );
}

export default HomeView;
