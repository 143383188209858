import { Link } from "react-router-dom";
import React from "react";
import ContactImg from "../assets/imges/grid-07.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ContactLink = () => {
  return (
    <Link to="/info" style={{ display: "grid" }}>
      <LazyLoadImage
        fetchpriority="high"
        rel="preload"
        src={ContactImg}
        alt="Project-cover"
        placeholderSrc="https://placeholder.pics/svg/600x300/DEDEDE/555555/loading"
        effect="blur"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
    </Link>
  );
};

export default ContactLink;
