import React, { useState } from "react";
import styled from "styled-components";
import HomeView from "../PopUpView/HomePopView";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import PopHomeViewItem from "../popUpWraper";

function PopHomeView({ data }) {
  const [showHomeView, setShowHomeView] = useState(false);
  const [dataHomeView, setDataHomeView] = useState({ name: "" });

  const getHomeView = (data) => {
    setShowHomeView(true);
    setDataHomeView(data);
  };

  const hideHomeView = () => {
    setShowHomeView(false);
  };

  return (
    <Container
      as={motion.div}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {data
        .sort((a, b) => a.secuence - b.secuence)
        .map((data) => (
          <PopHomeViewItem
            data={data}
            getHomeView={getHomeView}
            key={data.id}
          />
        ))}

      <HomeView
        show={showHomeView}
        onHide={hideHomeView}
        postName={dataHomeView.postName}
        photoGallery={dataHomeView.photoGallery}
        layoutProps={dataHomeView.layoutProps}
      />
    </Container>
  );
}

PopHomeView.propTypes = {
  data: PropTypes.array.isRequired,
};

const Container = styled.div`
  margin-top: -4rem;
  padding: 30px 0px 26px;
  display: grid;

  grid-template-columns: repeat(3, minmax(0, 1fr));

  justify-content: center;
  place-items: center stretch;

  ${(props) => props.ratio}

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px 8px;
    margin-top: -4rem;
    -webkit-overflow-scrolling: touch;
  }
`;

export default PopHomeView;
